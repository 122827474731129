import { useState } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import styles from './navbar.module.css';
import { Icon, IconKeys, MainLogo } from '..';

export const Navbar = () => {
  const [isDropdownMenuVisible, setDropdownMenuVisible] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

  const showGamified = true;
  const gamifiedURL = '/';
  const lttbURL = process.env.REACT_APP_LTTB_URL;

  const openDropdownToggle = () => {
    setDropdownMenuVisible(true);
  };

  const closeDropdownToggle = () => {
    setDropdownMenuVisible(false);
  };

  const handleMenuToggle = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  const resetMobileMenu = () => {
    setMobileMenuVisible(false);
  };

  const topics = [
    { url: `${lttbURL}/heart-healthy-topics/move-more/`, link: 'Move More' },
    { url: `${lttbURL}/heart-healthy-topics/eat-healthy/`, link: 'Eat Healthy' },
    { url: `${lttbURL}/heart-healthy-topics/control-blood-pressure/`, link: 'Control Blood Pressure' },
    { url: `${lttbURL}/heart-healthy-topics/manage-cholesterol/`, link: 'Manage Cholesterol' },
    { url: `${lttbURL}/heart-healthy-topics/manage-blood-sugar/`, link: 'Manage Blood Sugar' },
    { url: `${lttbURL}/heart-healthy-topics/work-with-a-doctor/`, link: 'Work with a Doctor' },
    { url: `${lttbURL}/heart-healthy-topics/stress-less/`, link: 'Stress Less' },
    { url: `${lttbURL}/heart-healthy-topics/quit-smoking/`, link: 'Quit Smoking' },
  ];

  return (
    <nav className={styles.navbar}>
      <div className="max-w-7xl mx-auto md:px-6 md:py-4">
        <div className="flex justify-end items-center p-2">
          <a href={lttbURL} className="w-44 md:w-56 mr-auto fill-white" aria-label="Live to the Beat">
            <MainLogo />
          </a>

          <nav role="navigation">
            <ul className="hidden md:flex items-center gap-11 uppercase text-sm mr-16">
              <li
                onMouseEnter={openDropdownToggle}
                onMouseLeave={closeDropdownToggle}
                onFocus={openDropdownToggle}
                onBlur={closeDropdownToggle}
              >
                <a href={`${lttbURL}/heart-healthy-topics`} className="py-4 flex gap-4 items-center">
                  Take Small Steps{' '}
                  <div className="w-2.5 aspect-square">
                    <Icon icon={IconKeys.CARET_DOWN} />
                  </div>
                </a>
                <ul
                  aria-label="submenu"
                  className={clsx(
                    { hidden: !isDropdownMenuVisible },
                    'normal-case font-base bg-black border-t-2 border-primary-2',
                  )}
                >
                  {topics.map((topic, index) => (
                    <li key={index}>
                      <a href={topic.url} onClick={closeDropdownToggle} className="block px-6 py-3">
                        {topic.link}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <a href={`${lttbURL}/newsroom`} className="py-4">
                  Media
                </a>
              </li>
              <li>
                <a href={`${lttbURL}/about`} className="py-4">
                  About
                </a>
              </li>
              {showGamified && (
                <li>
                  <Link to={gamifiedURL}>
                    <button className="px-5 py-1.5 rounded-full rounded-bl-none bg-gradient-to-b from-gradient-start to-gradient-stop text-white uppercase text-base">
                      Pulse Check
                    </button>
                  </Link>
                </li>
              )}
            </ul>
          </nav>

          <button onClick={handleMenuToggle} className="flex md:hidden p-3">
            <div className="w-9">
              <Icon icon={IconKeys.HAMBURGER} />
            </div>
          </button>
        </div>
      </div>

      <div
        className={clsx(
          { hidden: !isMobileMenuVisible },
          'absolute w-full bg-black md:hidden transition duration-300 ease-in text-center border-t-4 border-primary-3 text-sm uppercase shadow-xl',
        )}
      >
        <a
          href={`${lttbURL}/heart-healthy-topics`}
          onClick={resetMobileMenu}
          className={'p-3 flex gap-4 justify-center items-center'}
        >
          Take Small Steps
        </a>

        {topics.map((topic, index) => (
          <a href={topic.url} key={index} onClick={resetMobileMenu} className="block p-3 bg-neutral-3">
            {topic.link}
          </a>
        ))}

        <a href={`${lttbURL}/newsroom`} onClick={resetMobileMenu} className="block p-3">
          Media
        </a>
        <a href={`${lttbURL}/about`} onClick={resetMobileMenu} className="block p-3">
          About
        </a>
        {showGamified && (
          <Link to={gamifiedURL} onClick={resetMobileMenu}>
            <button className="m-3 px-5 py-1.5 rounded-full rounded-bl-none bg-gradient-to-b from-gradient-start to-gradient-stop text-white uppercase text-base">
              Pulse Check
            </button>
          </Link>
        )}
      </div>
    </nav>
  );
};
