import { useContext } from 'react';

import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { Button, Navbar } from '..';
import { HistoryContext } from '../../providers';

export const WelcomeBack = () => {
  const { setCodename } = useContext(HistoryContext);

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.target as HTMLFormElement);
    const newUserCodename = data.get('codename');
    if (newUserCodename) {
      setCodename(newUserCodename.toString().toUpperCase());
      navigate('/');
    }
  };

  return (
    <>
      <Helmet>
        <title>Live to the Beat | Welcome Back</title>
        <meta name="description" content="Resume your Pulse Check journey." />
        <meta property="og:title" content="Live to the Beat | Welcome Back" />
        <meta property="og:description" content="Resume your Pulse Check journey." />
        <meta property="og:image" content={`${process.env.REACT_APP_PULSE_CHECK_URL}/images/hero-onboarding.jpg`} />
        <meta property="og:url" content={`${process.env.REACT_APP_PULSE_CHECK_URL}/code`} />
        <meta property="og:type" content="website" />
      </Helmet>

      <Navbar />

      <div className="flex flex-col min-h-screen gap-4 justify-center items-center">
        <h3 className="text-3xl">Welcome back!</h3>
        <form className="flex flex-col gap-4 items-center" onSubmit={handleSubmit}>
          <label className="text-2xl font-bold" htmlFor="returning-codename">
            Enter your unique code
          </label>
          <input
            id="returning-codename"
            type="text"
            name="codename"
            className="text-black bg-white text-3xl font-loos font-bold w-32 p-4 text-center"
          />
          <button type="submit">
            <Button type="cta">Let’s Go!</Button>
          </button>
        </form>
      </div>
    </>
  );
};
